import React from "react";
import "./section3.scss";
import menusection3 from "../../../static/menu-section-3.webp";

const Section3 = () => {
  return (
    <div className="section-3-menu">
      <div className="section-3-wrapper-menu">
        <div className="section-3-text-menu">
          <h2>Getränke</h2>
          <p>
            {" "}
            Entdecken Sie unsere erlesene Auswahl an Getränken, Weinen und
            Schaumweinen sowie eine Vielfalt an Spirituosen und Bieren.
          </p>
        </div>
        <div className="section-3-image-menu">
          <img src={menusection3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section3;
