import React from "react";
import "./featured.scss";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const Featured = () => {
  return (
    <div className="featured">
      <div className="container">
        <div className="featured-wrapper">
          <div className="featured-item">
            <span>Was passiert</span>
            <h2>Neueste Nachrichten, Geschichten und Rezepte</h2>
            <button>DIE NACHRICHTEN</button>
          </div>
          <div className="featured-item-img">
            <span>
              <CameraAltIcon />
            </span>
            <span>Eindrücke</span>
            <h2>Das Restaurant</h2>
          </div>
          <div className="featured-item">
            <span>DAS PERFEKTE GESCHENK FÜR EINEN LIEBSTEN MENSCHEN</span>
            <h2>Restaurant Opatija Geschenkkarte</h2>
            <button>Jetzt kaufen</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
