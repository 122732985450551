import React from "react";
import "./section4.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";

const Section4 = () => {
  return (
    <div className="section-4">
      <div className="container">
        <div className="section-4-wrapper">
          <div className="item">
            <span>
              <CalendarMonthIcon />
            </span>
            <h3>Reservierungen</h3>
            <p>Werden dringend empfohlen</p>
          </div>
          <div className="item">
            <span>
              <SpaOutlinedIcon />
            </span>
            <h3>Vegetarisch</h3>
            <p>Vegetarisch auf Anfrage</p>
          </div>

          <div className="item">
            <span>
              <DirectionsCarFilledOutlinedIcon />
            </span>
            <h3>Parken</h3>
            <p>Parken ist verfügbar</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
