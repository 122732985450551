import React, { useEffect } from "react";
import "./impressum.scss";
import Section1 from "../../components/privacy/Section-1/Section1";
import Section2 from "../../components/privacy/Section-2/Section2";

const Impressum = () => {
  useEffect(() => {
    document.title = "Impressum / Datenschutz - Restaurant Opatija";
  }, []);
  return (
    <div>
      <Section1 />
      <Section2 />
    </div>
  );
};

export default Impressum;
