import React, { useEffect } from "react";
import Section1 from "../../components/about/Section-1/Section1";
// import Section2 from "../../components/about/Section-2/Section2";

const About = () => {
  useEffect(() => {
    document.title = "Über uns - Restaurant Opatija";
  }, []);
  return (
    <div>
      <Section1 />
      {/* <Section2 /> */}
    </div>
  );
};

export default About;
