import React from "react";
import "./section1.scss";
import restorant from "../../../static/opatija-6.webp";
import { Link } from "react-router-dom";

const Section1 = () => {
  return (
    <div className="section-1">
      <div className="section-1-wrapper">
        <div className="section-1-text">
          <h3>Finden Sie uns</h3>
          <p>Wo</p>
          <span>Hauenriede 39, 29525 Uelzen</span>

          <div className="btn">
            <Link
              className="link"
              to={`https://maps.app.goo.gl/Ty97oZ1YSJ2Tu8Kd8`}
              target="_blank"
            >
              <button>Karte anzeigen</button>
            </Link>
            {/* <Link className="link " to={`/tisch-reservieren`}>
              <button className="booking-btn">Tisch reservieren</button>
            </Link> */}
          </div>
          <div className="hours">
            <p>WANN</p>
            <span>Montag Ruhetag</span>
            <span>Dienstag bis Donnerstag 11:30–14:00, 17:30–21:30</span>
            <span>Freitag und Samstag 11:30–14:00, 17:30–22:00</span>
            <span>Sonntag 11:30–14:00, 17:30–21:00</span>
          </div>
          <div className="reservation-contact">
            <p>Reservierung</p>
            <span>
              Für Reservierungen wenden Sie sich bitte an in 0581 94879080
            </span>
          </div>
        </div>
        <div className="section-1-image">
          <img src={restorant} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
