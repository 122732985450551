import React, { useEffect } from "react";
import Info from "../../components/home/info/Info";
import Hero from "../../components/home/hero/Hero";
import Navbar from "../../components/home/navbar/Navbar";
import Banner from "../../components/home/banner/Banner";
import Featured from "../../components/home/featured/Featured";
import Featured2 from "../../components/home/featured-2/Featured2";
import Menu from "../../components/home/menu/Menu";
import Gallery from "../../components/home/gallery/Gallery";
import Testimonial from "../../components/home/testimonial/Testimonial";
// import Sponsor from "../../components/home/sponsor/Sponsor";
import Reservation from "../../components/home/reservation/Reservation";

const Home = () => {
  useEffect(() => {
    document.title = "Restaurant Opatija";
  }, []);
  return (
    <div>
      <Hero />
      <Banner />
      <Featured />
      <Featured2 />
      <Menu />
      <Gallery />
      <Testimonial />
      {/* <Sponsor /> */}
      <Reservation />
    </div>
  );
};

export default Home;
