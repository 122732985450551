import React, { useEffect } from "react";
import Section1 from "../../components/menu/Section-1/Section1";
import Section2 from "../../components/menu/Section-2/Section2";
import Section3 from "../../components/menu/Section-3/Section3";
import Section4 from "../../components/menu/Section-4/Section4";

const Menu = () => {
  useEffect(() => {
    document.title = "Menü - Restaurant Opatija";
  }, []);
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
  );
};

export default Menu;
