import React from "react";
import "./menu.scss";

const Menu = () => {
  return (
    <div className="menu">
      <div className="container">
        <div className="menu-wrapper">
          <div className="menu__section-1">
            <div className="menu__section-1__text">
              <h1>Vorspeisen</h1>
              <p>Bestellen Sie für den Tisch und verbreiten Sie die Liebe.</p>
            </div>
            <div className="vl"></div>

            <div className="menu__section-1__food">
              <div className="items">
                <div className="item">
                  <span>Gegrillter Feta Käse</span>
                  <span>mit frischen Tomaten und Knoblauch, Öl & Brot</span>
                </div>
                <div className="item-price">
                  <span>6,50 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Edamer Käse' paniert</span>
                  <span>Toast, Sauce Tartare oder Preiselbeeren</span>
                </div>
                <div className="item-price">
                  <span>6,50 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span> Crostini mit Gemüsevariation</span>
                  <span> und Sauerrahm</span>
                </div>
                <div className="item-price">
                  <span>6,50 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Feuersuppe^ (scharf)</span>
                  <span>Gulaschsuppe, Kidneybohnen & Mais</span>
                </div>
                <div className="item-price">
                  <span>5,00 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Traditionelle Gulaschsuppe</span>
                  <span></span>
                </div>
                <div className="item-price">
                  <span>5,00 €</span>
                </div>
              </div>
            </div>
          </div>

          <div className="menu__section-1">
            <div className="menu__section-1__text">
              <h1>Spezialitäten vom Grill</h1>
              <p>Bestellen Sie für den Tisch und verbreiten Sie die Liebe.</p>
            </div>
            <div className="vl"></div>
            <div className="menu__section-1__food">
              <div className="items">
                <div className="item">
                  <span>Cevapcici-Burger </span>
                  <span>Cevapcici, Magerspeck & Salat, Pommes Frites</span>
                </div>
                <div className="item-price">
                  <span>14,90 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Steak-Burger</span>
                  <span>
                    {" "}
                    gegrillte Paprika, gebratene Zwiebeln, Burgerdip, Salza
                    Sauce, Pommes Frites & Salat
                  </span>
                </div>
                <div className="item-price">
                  <span>15,50 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Cevapcici</span>
                  <span>Magerspeck, Djuvecreis, Pommes Frites & Salat</span>
                </div>
                <div className="item-price">
                  <span>14,50 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Bifteki</span>
                  <span>
                    {" "}
                    Hackfleisch gefüllt mit Weichkäse, Djuveēreis', Pommes
                    Frites & Salat
                  </span>
                </div>
                <div className="item-price">
                  <span>18,50 € </span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Grill-Leber</span>
                  <span>
                    Rinderleber, Kartoffelpüree, geröstete Zwiebeln,
                    Apfelscheiben & Salat
                  </span>
                </div>
                <div className="item-price">
                  <span>15.50 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Opatija Bauern-Teller</span>
                  <span>
                    Hacksteak, Hähnchenschnitzel, gegrillter Feta, Cevapcici,
                    Djuvecreis, Pommes Frites & Salat
                  </span>
                </div>
                <div className="item-price">
                  <span>16,50 €</span>
                </div>
              </div>
            </div>
          </div>

          <div className="menu__section-1">
            <div className="menu__section-1__text">
              <h1>desserts</h1>
              <p>Bestellen Sie für den Tisch und verbreiten Sie die Liebe.</p>
            </div>
            <div className="vl"></div>

            <div className="menu__section-1__food">
              <div className="items">
                <div className="item">
                  <span>Palačinke</span>
                  <span>
                    {" "}
                    2 Stück mit Eis (Vanillegeschmack), mit heißen Himbeeren,
                    Kirschen oder Schokoladesauce
                  </span>
                </div>
                <div className="item-price">
                  <span>6,00 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Eisbecher</span>
                  <span>
                    3 Kugeln Eis (Vanillegeschmack), mit heißen Himbeeren,
                    Kirschen oder Schokoladesauce
                  </span>
                </div>
                <div className="item-price">
                  <span>5,50 €</span>
                </div>
              </div>
              <div className="items">
                <div className="item">
                  <span>Cupavci</span>
                  <span>
                    {" "}
                    Schoko-Kokos-Küchlein aus dem Balkan mit einer Kugel Eis
                    (Vanillegeschmack), und Schokoladesauce
                  </span>
                </div>
                <div className="item-price">
                  <span>7,50 €</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
