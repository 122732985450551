import React from "react";
import "./footer.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="welcome-footer">
            <p>WILLKOMMEN IM RESTAURANT OPATIJA</p>
            <span>
              Das 2024 gegründete Restaurant Opatija richtet sich an alle, die
              gerne weit und breit wandern.
            </span>
            <span>
              Wir möchten Sie zu einem kulinarischen Abenteuer einladen, bei dem
              Sie unentdeckte Gourmeterlebnisse entdecken.
            </span>
          </div>
          <div className="visit-footer">
            <p>besuchen</p>
            <span>
              <LocationOnIcon /> Hauenriede 39, 29525 Uelzen
            </span>
            <p>sprechen</p>
            <span>
              <PhoneIphoneIcon /> 0581 94879080
            </span>
            <p>schreiben</p>
            <span>
              <EmailIcon />
              restaurantopatija23@gmail.com
            </span>
          </div>
          <div className="reservation-footer">
            <p>Reservierungen</p>
            <Link className="link" to={`/kontakt`} onClick={scrollToTop}>
              <button className="btn-booking">Tisch reservieren</button>
            </Link>
            <p>Öffnungszeiten</p>
            <span>
              {" "}
              Dienstag durch Donnerstag <br /> 11:30–14:00, 17:30–21:30
            </span>
            <span>
              Samstag/Sonntag <br />
              11:30–14:00, 17:30–22:00 <br />
              11:30–14:00, 17:30–21:00
            </span>
          </div>
          <div className="about-footer">
            <p>über</p>
            <div className="privacy-policy">
              <span>Reservation Policy</span>
              <Link className="link" to={`/impressum-datenschutz`}>
                {" "}
                <span onClick={scrollToTop}>IMPRESSUM / DATENSCHUTZ</span>
              </Link>
              <span>Health & Safety</span>
            </div>
            <p>folge mit</p>
            <div className="icon">
              <span>
                <InstagramIcon />
              </span>
              <span>
                <FacebookIcon />
              </span>
              <span>
                <TwitterIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-text">
            <span>SCHMECKEN SIE DEN UNTERSCHIED </span>
            <p>Restaurant Opatija</p>
            <span>© 2024 ABF. ALLE RECHTE VORBEHALTEN.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
