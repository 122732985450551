import React from "react";
import "./hero.scss";

const Hero = () => {
  return (
    <div className="hero-banner">
      <div className="container">
        <div className="text-hero">
          <span>GENIEßEN SIE EIN UNVERGESSLICHES KULINARISCHES ERLEBNIS</span>
          <h1>Restaurant Opatija</h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
