import React, { useState } from "react";
import "./section4.scss";

const Section4 = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="menu-section-4">
      <div className="container">
        <div className="menu-wrapper__section-4">
          <div className="menu__section-4">
            <div className="menu__section-4__text">
              <h1>Getränkemenü</h1>
            </div>

            <div className="vl"></div>
            <div className="tabs">
              <div className="item">
                <span
                  className={activeTab === 1 ? "tab vh" : "tab"}
                  onClick={() => handleTabClick(1)}
                >
                  GETRÄNKE
                </span>
                <span
                  className={activeTab === 2 ? "tab vh" : "tab"}
                  onClick={() => handleTabClick(2)}
                >
                  WEIN & SCHAUMWEINS
                </span>

                <span
                  className={activeTab === 3 ? "tab vh" : "tab"}
                  onClick={() => handleTabClick(3)}
                >
                  SPIRITUOSEN & Biere
                </span>
              </div>
            </div>
            <div className="menu__section-4__drink">
              {activeTab === 1 && (
                <div className="menu-items-wine active-wrapper">
                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Getränke</h2>
                    </div>
                    <div className="collection-name">
                      {/* <span>glass</span>
                                            <span> bottle</span> */}
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Gerolsteiner <span>Sprudel 0,25l</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2.80 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Gerolsteiner <span>Still, Medium 0,75l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>6,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Coca Cola <span>0,31l / 0,4l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3,20 € / 4,20 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Coca Cola ZERO <span>0,33l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3,20 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Sprite <span>0,3l / 0,4l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3,20 € / 4,20 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Fanta <span>0,3l / 0,4l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3,20 € / 4,20 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>
                          Cola-Mix <span>0,3l / 0,4l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3,20 € / 4,20 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>
                          Bitter Lemon <span> 0,2l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2.80 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Tonic Water <span>0,2l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2.80 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>
                          Ginger Ale <span>0,2l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2.80 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>
                          Apfelsaft <span> trüb (Granini) 0,3l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3.50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>
                          Orangensaft <span>(Granini) 0,3l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3.50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>
                          Rhabarbersaft <span> (Granini) 0,3l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3.50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>
                          Maracujasaft <span>(Granini) 0,3l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3.50 €</span>
                      </div>
                    </div>
                  </div>

                  <div className="menu-items-wrapper">
                    <div className="text-items">
                      <h2>Warme Getränke</h2>
                    </div>

                    <div className="items">
                      <div className="item">
                        <span>Espresso</span>
                      </div>
                      <div className="item-price">
                        <span>2,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Doppelter Espresso</span>
                      </div>
                      <div className="item-price">
                        <span>4,40 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Tasse Kaffee</span>
                      </div>
                      <div className="item-price">
                        <span>2,60 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Cappuccino®</span>
                      </div>
                      <div className="item-price">
                        <span>2,80 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Milchkaffee®</span>
                      </div>
                      <div className="item-price">
                        <span>3,20 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Latte Macchiato®</span>
                      </div>
                      <div className="item-price">
                        <span>4,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Heißer Kakao®</span>
                      </div>
                      <div className="item-price">
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Glas Tee</span>
                        <span>
                          Schwarz, Kamille, Früchte, Pfefferminz, Kräuter,
                          Grüner, Waldbeere, Rooibos
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2,50 €</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <div className="menu-items-cocktails active-wrapper">
                  <div className="text-items">
                    <h2>Wein & Schaumweins</h2>
                  </div>

                  <div className="menu-items-wrapper-cocktails">
                    <div className="items">
                      <div className="item">
                        <span>Weißwein LASKI RIESLING</span>
                        <span>
                          halbtrocken Slowenien, Vipava Tal Qualitätswein
                        </span>
                      </div>
                      <div className="item-price">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Weißwein GRASEVINA</span>
                        <span>
                          trocken Kroatien, mittel Slavonien Qualitätswein
                        </span>
                      </div>
                      <div className="item-price">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Rosé ADRIA</span>
                        <span>
                          halbtrocken Slowenien, Vipava Tal Qualitätswein
                        </span>
                      </div>
                      <div className="item-price">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Rosé BENKOVAC</span>
                        <span>
                          trocken Kroatien, Norddalmatien Qualitätswein
                        </span>
                      </div>
                      <div className="item-price">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>Rotwein PELJESAC</span>
                        <span>
                          halbtrocken Kroatien, Süddalmatien Qualitäatswein
                        </span>
                      </div>
                      <div className="item-price">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <div className="item">
                          <span>Rotwein PLAVAC</span>
                          <span>
                            trocken Kroatien, Süddalmatien Qualitäatswein
                          </span>
                        </div>
                      </div>
                      <div className="item-price">
                        <span></span>
                        <span></span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <div className="item">
                          <span>Glas Wein 0,25l</span>
                        </div>
                      </div>
                      <div className="item-price">
                        <span>5,90 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <div className="item">
                          <span>Flasche Wein 1,0l</span>
                        </div>
                      </div>
                      <div className="item-price">
                        <span>21,50 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <div className="item">
                          <span>Weißweinschorle 0,25l</span>
                        </div>
                      </div>
                      <div className="item-price">
                        <span>4,20 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <div className="item">
                          <span>Schaumwein Henkel</span>
                          <span>trocken, Wiesbaden, Rieslingsekt</span>
                        </div>
                      </div>
                      <div className="item-price-collection">
                        <span>Glas</span>
                        <span>Flasche</span>
                      </div>
                      <div className="item-price-wein">
                        <span>3,50 €</span>
                        <span>20,00 €</span>
                      </div>
                    </div>

                    <div className="items">
                      <div className="item">
                        <div className="item">
                          <span>Prosecco</span>
                          <span>trocken, Italien, Valmarone</span>
                        </div>
                      </div>
                      <div className="item-price-collection">
                        <span>Glas</span>
                        <span>Flasche</span>
                      </div>
                      <div className="item-price-wein">
                        <span>3,50 €</span>
                        <span>20,00 €</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 3 && (
                <div className="menu-items-biere active-wrapper">
                  <div className="menu-items-wrapper-spirituosen">
                    <div className="text-items">
                      <h2>Spirituosen</h2>
                    </div>
                    <div className="collection-name">
                      {/* <span>glass</span>
                                            <span> bottle</span> */}
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Linie <span>Aquavit / 41,5% vol</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Malteser <span>Aquavit / 40% vol</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Jubilaums <span> Aquavit / 35% vol</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Jägermeister <span> 40% vol</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Fernet Branca <span> 40% vol</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>2 cl</span>
                        <span>2,80 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Sljivovic <span>Badel, 40% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Pelinkovac <span> Maraska, 28% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2 cl </span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Travarica <span>Badel, 47,5% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Wodka <span>40% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2 cl</span>
                        <span>2,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Sambuca <span>Badel, 40% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Ramazzotti <span>30% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2 cl</span>
                        <span>3,00 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Ouzo 12 <span>Badel, 38% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>2 cl</span>
                        <span>2,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Baileys <span>17% vol</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>4 cl</span>
                        <span>3,50 €</span>
                      </div>
                    </div>
                  </div>
                  <div className="menu-items-wrapper-biere">
                    <div className="text-items">
                      <h2>Biere</h2>
                    </div>
                    <div className="collection-name"></div>
                    <div className="items">
                      <div className="item">
                        <span>
                          König Pilsener <span>0,3l / 0,5l</span>
                        </span>
                      </div>

                      <div className="item-price">
                        <span>3.50 € / 4.50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Alster <span>0,3l / 0,5l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3.50 € / 4,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Th. König Zwickl <span>0,3l / 0,5l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3,80 € / 4,80 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          König Pilsener (alkoholfrei) <span>0,3l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>3,50 €</span>
                      </div>
                    </div>
                    <div className="items">
                      <div className="item">
                        <span>
                          Benediktiner Weißbier <span>0,5l</span>
                        </span>
                      </div>
                      <div className="item-price">
                        <span>4,80 €</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
