import React from "react";
import "../privacy.scss";

const Section2 = () => {
  return (
    <div className="section-privacy-2">
      <div className="container">
        <div className="wrapper">
          <div className="items">
            <div className="item">
              <h4>Impressum</h4>
              <span>Restaurant Opatija</span>
              <span>Hauenriede 39</span>
              <span> 29525 Uelzen</span>
              <span>Phone: 0581 94879080</span>
              <span>Email: restaurantopatija23@gmail.com</span>
            </div>
            <div className="item">
              <h4>Aufsichtsbehörde:</h4>
              <span>Handwerkskammer Lüneburg/Stade</span>
            </div>
            <div className="item">
              <h4 style={{ fontSize: "1.5rem" }}>Vertreten durch:</h4>
              <span>Xhevat Mustafa</span>
            </div>
            <div className="item">
              <h4>Datenschutz</h4>
              <span>
                Wir sind uns bewusst, dass der Schutz Ihrer Privatsphäre bei der
                Nutzung unserer Websites für Sie wichtig ist. Wir nehmen den
                Schutz Ihrer persönlichen Daten sehr ernst. Deshalb möchten wir,
                dass Sie wissen, wann wir welche Daten speichern und wie wir sie
                verwenden. Wir möchten Sie daher mit dieser Datenschutzerklärung
                über unsere Datenschutzmaßnahmen informieren.
              </span>
            </div>
            <div className="item">
              <h4>Erhebung personenbezogener Daten</h4>
              <span>
                Im Folgenden informieren wir Sie über die Erhebung
                personenbezogener Daten bei der Nutzung unserer Website.
                Personenbezogene Daten sind alle Daten, die sich auf Sie
                persönlich beziehen lassen, z. B. Name, Adresse,
                E-Mail-Adressen, Nutzerverhalten. Wenn Sie uns per E-Mail oder
                über ein Kontaktformular kontaktieren, werden die von Ihnen
                angegebenen Daten (Ihre E-Mail-Adresse, Ihr Name und
                gegebenenfalls Ihre Telefonnummer) von uns gespeichert, um Ihre
                Fragen zu beantworten. Die in diesem Zusammenhang anfallenden
                Daten werden gelöscht, nachdem die Speicherung nicht mehr
                erforderlich ist. Wenn Sie die Website nur zu
                Informationszwecken nutzen, d.h. wenn Sie sich nicht
                registrieren oder uns anderweitig Informationen zur Verfügung
                stellen, werden nur die Daten erhoben, die Ihr Browser an den
                von uns verwendeten Server übermittelt. Wenn Sie unsere Website
                aufrufen möchten, werden folgende Daten erhoben, die für uns
                technisch erforderlich sind, um Ihnen unsere Website anzuzeigen
                und Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage
                ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
              </span>

              <ul>
                <li>- IP-Adresse</li>
                <li>- Datum und Uhrzeit der Anfrage</li>
                <li>- Zeitzone Unterschied zu Greenwich Mean Time (GMT)</li>
                <li>- Inhalt der Anfrage (spezifische Seite)</li>
                <li>- Zugriffsstatus/HTTP-Statuscode</li>
                <li>- übertragene Datenmenge</li>
                <li>- Website, von der die Anforderung kommt</li>
                <li>- Browser</li>
                <li>- Betriebssystem und dessen Benutzeroberfläche</li>
                <li>- Sprache und Version der Browsersoftware.</li>
              </ul>
            </div>
            <div className="item">
              <h4>Verantwortlicher</h4>
              <span>
                Die für die Verarbeitung Verantwortliche Person im Sinne des
                Art. 4 Abs. 7 EU-Datenschutzgrundverordnung (DSGVO) finden Sie
                im Impressum.
              </span>
            </div>
            <div className="item">
              <h4>Rechtsgrundlagen der Verarbeitung</h4>
              <span>
                Wir verarbeiten Daten gemäß Art. 6 Abs. 1 lit. a) b) c) f)
                DSGVO. Das bedeutet, dass wir personenbezogene Daten nur
                verarbeiten, wenn eine Einwilligung vorliegt (lit. a), soweit
                dies zur Erfüllung eines Vertrags oder vorvertraglicher
                Maßnahmen erforderlich ist (lit. b), wir rechtlich dazu
                verpflichtet sind (lit. c) oder ein berechtigtes Interesse an
                der Verarbeitung besteht (lit. f).
              </span>
            </div>
            <div className="item">
              <h4>Verwendungszwecke</h4>
              <span>
                Die von Ihnen erhobenen personenbezogenen Daten werden von uns
                nur für die Bereitstellung der von Ihnen angeforderten Produkte
                oder Dienstleistungen oder für andere Zwecke, für die Sie Ihre
                Einwilligung gegeben haben, verwendet, es sei denn, wir sind
                gesetzlich dazu verpflichtet, dies anders zu handhaben.
              </span>
            </div>
            <div className="item">
              <h4>Rechte der betroffenen Person</h4>
              <span>
                Sie haben folgende Rechte in Bezug auf Ihre personenbezogenen
                Daten:
              </span>

              <ul>
                <li>- Recht auf Auskunft,</li>
                <li>- Recht auf Berichtigung oder Löschung,</li>
                <li>- Recht auf Einschränkung der Verarbeitung,</li>
                <li>- Recht auf Widerspruch gegen die Verarbeitung,</li>
                <li>- Recht auf Datenübertragbarkeit.</li>
              </ul>
              <span>
                Sie haben auch das Recht, bei einer Datenschutz-Aufsichtsbehörde
                über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu
                beschweren
              </span>
            </div>
            <div className="item">
              <h4>Recht auf Widerruf oder Widerspruch der Datenverarbeitung</h4>
              <span>
                Wenn Sie Ihre Einwilligung zur Verarbeitung Ihrer Daten gegeben
                haben, können Sie diese jederzeit widerrufen. Ein solcher
                Widerruf wirkt sich auf die Zulässigkeit der Verarbeitung Ihrer
                personenbezogenen Daten aus, nachdem Sie ihn uns gegenüber
                ausgesprochen haben.
              </span>
            </div>
            <div className="item">
              <h4>Speicherung von Daten</h4>
              <span>
                Wir speichern personenbezogene Daten nur solange, wie es zur
                Erbringung eines von Ihnen angeforderten Dienstes oder für den
                Sie uns Ihre Einwilligung gegeben haben, erforderlich ist,
                sofern keine weiteren gesetzlichen Verpflichtungen bestehen, wie
                laufende gerichtliche Verfahren.
              </span>
            </div>
            <div className="item">
              <h4>Verwendung von Cookies</h4>
              <span>
                Neben den oben genannten Daten werden bei der Nutzung unserer
                Website auch Cookies auf Ihrem Computer gespeichert. Cookies
                sind kleine Textdateien, die auf Ihrer Festplatte gespeichert
                werden, dem von Ihnen genutzten Browser zugeordnet sind und
                durch die bestimmte Informationen an den Ort fließen, der das
                Cookie setzt (hier von uns). Cookies können keine Programme
                ausführen oder Viren auf Ihren Computer übertragen. Sie dienen
                dazu, das Internet-Angebot insgesamt benutzerfreundlicher und
                effektiver zu machen. <br /> 1.Diese Website verwendet die
                folgenden Arten von Cookies, deren Umfang und Funktionsweise im
                Folgenden erläutert werden:
              </span>

              <ul>
                <li>Temporäre Cookies (siehe b)</li>
                <li>Persistente Cookies (siehe c).</li>
              </ul>

              <span>
                2.Temporäre Cookies werden automatisch gelöscht, wenn Sie Ihren
                Browser schließen. Hierzu zählen insbesondere die
                Session-Cookies. Diese speichern eine sogenannte Session-ID, mit
                der verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
                zugeordnet werden können. So kann Ihr Computer erkannt werden,
                wenn Sie auf unsere Website zurückkehren. Die Session-Cookies
                werden gelöscht, wenn Sie sich ausloggen oder den Browser
                schließen. <br /> 3.Persistente Cookies werden automatisch nach
                einer vorgegebenen Dauer gelöscht, die je nach Cookie variieren
                kann. Die Cookies können Sie jederzeit in den
                Sicherheitseinstellungen Ihres Browsers löschen. <br /> 4.Sie
                können Ihren Browser-Einstellungen entsprechend konfigurieren
                und z.B. Drittanbieter-Cookies oder alle Cookies ablehnen. Wir
                weisen darauf hin, dass Sie möglicherweise nicht alle Funktionen
                dieser Website nutzen können.
              </span>
            </div>
            <div className="item">
              <h4>Weitere Funktionen und Angebote unserer Website</h4>
              <span>
                Neben der rein informativen Nutzung unserer Website bieten wir
                verschiedene Dienste an, die Sie bei Interesse nutzen können.
                Hierfür müssen Sie in der Regel zusätzliche personenbezogene
                Daten angeben, die wir zur Erbringung des jeweiligen Dienstes
                verwenden und für die die oben genannten
                Datenverarbeitungsgrundsätze gelten. In einigen Fällen nutzen
                wir externe Dienstleister zur Verarbeitung Ihrer Daten. Diese
                wurden von uns sorgfältig ausgewählt und beauftragt, sind an
                unsere Weisungen gebunden und werden regelmäßig überprüft.
                Darüber hinaus können wir Ihre personenbezogenen Daten an Dritte
                weitergeben, wenn wir gemeinsam mit Partnern an Kampagnen,
                Wettbewerben, Vertragsabschlüssen oder ähnlichen
                Dienstleistungen teilnehmen. Detailliertere Informationen
                erhalten Sie hierzu, wenn Sie Ihre personenbezogenen Daten
                angeben oder unten in der Beschreibung des Angebots. Wenn unsere
                Dienstleister oder Partner sich in einem Land außerhalb des
                Europäischen Wirtschaftsraums (EWR) befinden, informieren wir
                Sie über die Folgen dieser Umstände in der Beschreibung des
                Angebots.
              </span>
            </div>
            <div className="item">
              <h4>Sicherheit</h4>
              <span>
                Wir setzen technische und organisatorische Sicherheitsmaßnahmen
                ein, um die von Ihnen bereitgestellten Daten vor
                unbeabsichtigter oder vorsätzlicher Manipulation, Verlust,
                Zerstörung oder Zugriff durch unbefugte Personen zu schützen.
                Bei der Erhebung und Verarbeitung von personenbezogenen Daten
                wird die Information in verschlüsselter Form übertragen, um
                einen Missbrauch der Daten durch Dritte zu verhindern. Unsere
                Sicherheitsmaßnahmen werden kontinuierlich an den
                technologischen Fortschritt angepasst.
              </span>
            </div>{" "}
            <div className="item">
              <h4>Anpassungen</h4>
              <span>
                Die stetige Entwicklung des Internets macht es erforderlich,
                unsere Datenschutzerklärung von Zeit zu Zeit anzupassen. Wir
                behalten uns das Recht vor, jederzeit entsprechende Änderungen
                vorzunehmen.
              </span>
            </div>{" "}
            <div className="item">
              <h4>Nutzungsstatistiken</h4>
              <span>
                Wenn Sie unsere Website besuchen, erfassen wir anonym, wie der
                Besucher zu unserer Website gelangt ist und wie viele Nutzer die
                Website aufgerufen haben (Suchmaschine, Link, direkte Eingabe
                oder Werbung). Die Daten werden nicht verwendet, um einzelne
                Besucher zu identifizieren, sondern nur um quantitative Werte zu
                übermitteln. Die Statistiken können nicht verwendet werden, um
                Ihr Verhalten auf anderen Websites zu verstehen. Die Daten
                werden nicht mit anderen Diensten verknüpft. Wenn Sie die
                Einstellung von Cookies in Ihrem Browser deaktiviert haben,
                werden Ihre Besuche nicht aufgezeichnet.
              </span>
            </div>{" "}
            <div className="item">
              <h4>Inhalte von Drittanbietern auf der Website</h4>
              <span>
                Unsere Website integriert Inhalte von anderen Anbietern. Dabei
                kann es sich um reine Inhaltelemente (z.B. Nachrichten) handeln,
                aber auch um Widgets (Funktionen wie Wetterberichte) oder
                beispielsweise Schriftarten und technische Bibliotheken. Aus
                technischen Gründen wird dies durch das Laden dieses Inhalts aus
                dem Browser von anderen Servern aus durchgeführt. In diesem
                Zusammenhang werden die IP-Adresse, die derzeit von Ihrem
                Browser verwendet wird, und der von der anfordernden System
                verwendete Browser übertragen. Bitte beachten Sie in diesem
                Zusammenhang die jeweiligen Datenschutzerklärungen der
                Drittanbieter.
              </span>
              <span>
                Wir verwenden Google Fonts auf unserer Website, um die
                Benutzererfahrung durch die Anzeige von benutzerdefinierten
                Schriftarten zu verbessern. Google Fonts ist ein von Google Inc.
                bereitgestellter Drittanbieterdienst. Wenn Sie unsere Website
                besuchen, kann Ihr Browser automatisch die erforderlichen
                Schriftarten herunterladen und zwischenspeichern. Dadurch kann
                Ihre IP-Adresse an Google übertragen werden und Google kann
                bestimmte Informationen wie Nutzungsdaten und Interaktionen mit
                den Schriftarten sammeln. Weitere Informationen darüber, wie
                Google die von ihm gesammelten Daten behandelt, finden Sie in
                der Datenschutzerklärung von Google.
              </span>

              <span>
                Wir verwenden Google Analytics, um Informationen darüber zu
                sammeln, wie Besucher unsere Website nutzen. Google Analytics
                ist ein von Google Inc. bereitgestellter Drittanbieterdienst,
                der Cookies verwendet, um Daten über die Website-Nutzung zu
                sammeln und zu analysieren. Die von diesen Cookies generierten
                Informationen (einschließlich Ihrer IP-Adresse) werden an Google
                in den USA übertragen und dort gespeichert. Google wird diese
                Informationen verwenden, um Ihre Nutzung der Website zu
                bewerten, Berichte über die Website-Aktivität zu erstellen und
                andere Dienste im Zusammenhang mit der Website-Aktivität und der
                Internetnutzung bereitzustellen. Google kann diese Informationen
                auch an Dritte weitergeben, wenn dies gesetzlich vorgeschrieben
                ist oder wenn diese Dritten die Informationen im Auftrag von
                Google verarbeiten. Sie können Google Analytics deaktivieren,
                indem Sie das Browser-Add-On zur Deaktivierung von Google
                Analytics herunterladen, das hier verfügbar ist:
                <a href=" https://tools.google.com/dlpage/gaoptout.">
                  {" "}
                  https://tools.google.com/dlpage/gaoptout
                </a>{" "}
                Weitere Informationen darüber, wie Google die über Google
                Analytics gesammelten Daten behandelt, finden Sie in der
                Datenschutzerklärung von Google.
              </span>
            </div>{" "}
            <div className="item">
              <h4>Routenplaner</h4>
              <span>
                Wir verwenden Google Maps auf unserer Website, um Ihnen einen
                interaktiven Routenplaner zur Verfügung zu stellen. Durch die
                Nutzung dieses Dienstes stimmen Sie zu, dass Daten gemäß den
                Google Maps-Nutzungsbedingungen verwendet werden dürfen
                <a href="https://www.google.com/intl/de_de/help/terms_maps/">
                  (https://www.google.com/intl/de_de/help/terms_maps/)
                </a>
                . Wenn Sie beispielsweise die Karte nutzen, indem Sie auf unsere
                dort aufgeführten Standorte klicken, erfassen wir keine Daten
                von Ihnen. Das Unternehmen Google LLC, 1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA kann jedoch im Rahmen von
                Google Maps personenbezogene oder personenbezogene Daten
                erheben. Wir können nicht beeinflussen, welche Daten Google mit
                Google Maps erfasst oder wie Google diese Daten verarbeitet und
                auswertet. Bitte beachten Sie daher die Nutzungsbedingungen für
                Google Maps
                <a href="https://www.google.com/intl/de_de/help/terms_maps/">
                  (https://www.google.com/intl/de_de/help/terms_maps/)
                </a>
                , die unter{" "}
                <a href="https://policies.google.com/privacy?hl=de">
                  https://policies.google.com/privacy?hl=de
                </a>{" "}
                verfügbar sind.
              </span>
            </div>{" "}
            <div className="item">
              <h4 style={{ fontSize: "1.5rem" }}>
                Hinweis gemäß § 36 Verbraucherstreitbeilegung
              </h4>
              <span>
                Wir werden nicht an einem Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle im Sinne des VSBG teilnehmen und
                sind dazu auch nicht verpflichtet.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
